import React, {useEffect, useState} from 'react';

function useGetInnerWith(props) {
  const [innerWith, setInnerWith] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setInnerWith(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  return innerWith;
}

export default useGetInnerWith;
