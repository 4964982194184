import React from 'react'
import { Tabs } from 'antd'
import Image from 'next/image'
import {i18n, useTranslation} from 'next-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import useGetInnerWith from '../../hooks/useGetInnerWith'

function AboutEsim(props) {
  const { t } = useTranslation('aboutEsim')
  const innerWidth = useGetInnerWith()
  const currentLanguage = i18n.language

  return (
    <div className="about-esim">
      <div className={'esim-benefit'}>
        <div className="container">
          <div className="esim-benefit-content">
            <Image
              alt="esim-benefit"
              src="/images/aboutEsim/esim-benefits.png"
              width="310"
              height="477"
            />
            <div className="esim-benefit-right">
              <h4 className="title">{t('esim_benefit.title')}</h4>
              <ul className="esim-benefit-list">
                {t('esim_benefit.benefit_list', { returnObjects: true }).map(
                  (item, index) => (
                    <li key={index}>{item.content}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="esim-benefit-sp">
        <div className="container esim-benefit-sp-container">
          <h4 className="title">{t('esim_benefit.title')}</h4>
          <Swiper
            slidesPerView={2.1}
            spaceBetween={30}
            loop={true}
            modules={[Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            // modules={[Pagination]}
            className="mySwiper"
          >
            {t('esim_benefit.benefit_list', { returnObjects: true }).map(
              (item, index) => (
                <SwiperSlide key={index}>
                  <div className="esim-benefit-item">
                    <div className="circle"></div>
                    <p className="esim-benefit-content">{item.content}</p>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </div>

      <div className={'how-esim-work'}>
        <div className="container">
          <div className="esim-work-content">
            <p>{t('esim_work.description')}</p>
            <h4>{t('esim_work.title')}</h4>
          </div>
          <div className="esim-work-step">
            <Swiper
              slidesPerView={innerWidth > 1024 ? 3 : 1}
              spaceBetween={30}
              loop={true}
              // modules={[Pagination]}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              className="mySwiper"
            >
              {(t('esim_work.work_list', { returnObjects: true }) || []).map(
                (item, index) => (
                  <SwiperSlide key={index}>
                    <div className="esim-work-step-item">
                      <Image
                        alt="esim-step"
                        src={item.image}
                        width="250"
                        height="500"
                      />
                      <p className="esim-step-title">{item.title}</p>
                      <p className="esim-step-desc">{item.content}</p>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
            {/*{*/}
            {/*  (t('esim_work.work_list', {returnObjects: true}) || []).map((item, index) => (*/}
            {/*    <div className='esim-work-step-item'>*/}
            {/*      <Image alt='esim-step' src={item.image} width='250' height='500'/>*/}
            {/*        <p className='esim-step-title'>{item.title}</p>*/}
            {/*        <p className='esim-step-desc'>{item.content}</p>*/}
            {/*      </div>*/}
            {/*    ))*/}
            {/*}*/}
          </div>
        </div>
      </div>

      <div className={'device-esim-support'}>
        <div className="container">
          <div className="device-esim-support-content">
            <div className="device-esim-support-left">
              <h4>{t('device_support.title')}</h4>
              <p className="device-support-desc">
                {t('device_support.description')}
              </p>
              <Tabs rootClassName="device-support-tabs">
                <Tabs.TabPane
                  tab={t('device_support.device_list.apple.brand_name')}
                  key="1"
                >
                  <div className="device-list">
                    {(
                      t('device_support.device_list.apple.list', {
                        returnObjects: true,
                      }) || []
                    ).map((item, index) => (
                      <p className="device-list-item" key={index}>
                        <Image
                          alt=""
                          src="/images/aboutEsim/check-icon.svg"
                          width="15"
                          height="15"
                        />
                        {item}
                      </p>
                    ))}
                  </div>
                  <p className="list-item-note">
                    {t('device_support.device_list.apple.note')}
                  </p>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={t('device_support.device_list.samsung.brand_name')}
                  key="2"
                >
                  <div className="device-list">
                    {(
                      t('device_support.device_list.samsung.list', {
                        returnObjects: true,
                      }) || []
                    ).map((item, index) => (
                      <p className="device-list-item" key={index}>
                        <Image
                          alt=""
                          src="/images/aboutEsim/check-icon.svg"
                          width="15"
                          height="15"
                        />
                        {item}
                      </p>
                    ))}
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={t('device_support.device_list.google.brand_name')}
                  key="3"
                >
                  <div className="device-list">
                    {(
                      t('device_support.device_list.google.list', {
                        returnObjects: true,
                      }) || []
                    ).map((item, index) => (
                      <p className="device-list-item" key={index}>
                        <Image
                          alt=""
                          src="/images/aboutEsim/check-icon.svg"
                          width="15"
                          height="15"
                        />
                        {item}
                      </p>
                    ))}
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={t('device_support.device_list.other.brand_name')}
                  key="4"
                >
                  <div className="device-list">
                    {(
                      t('device_support.device_list.other.list', {
                        returnObjects: true,
                      }) || []
                    ).map((item, index) => (
                      <p className="device-list-item" key={index}>
                        <Image
                          alt=""
                          src="/images/aboutEsim/check-icon.svg"
                          width="15"
                          height="15"
                        />
                        {item}
                      </p>
                    ))}
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
            <div className="device-esim-support-right">
              <Image
                alt="device-support"
                src={`/images/aboutEsim/device-support-${currentLanguage}.gif`}
                width="204"
                height="416"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutEsim
